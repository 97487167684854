/* eslint-disable @typescript-eslint/camelcase */
import { ICartItem } from '../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import { setCookie } from './cookie'
import { IOrderResponse } from '../../pages/checkout-confirmation'
import { IUnbxdProduct } from '../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { IMorCartPrice } from '../components/Tenant/Context/MorCartContext'
import { IErrorModalType } from '../components/Tenant/Layout/Cart/ErrorModal'
import { getStoreCode } from '../components/Utils/getStoreCodeFromCookie'
import { getCurrentProductFromVariants } from '../components/Utils/productUtils'
import { getStorageValue, saveStorageValue } from './localStorage'
import { IDeliveryInformation, IMerceOrder } from '../../pages/checkout'
import { getProductsBySku } from '../modules/products/productQuery'
import { IFinanceCardType } from '../components/Tenant/Services/GERS/Associate/customer'

export enum DATA_LAYER_EVENT_NAME {
  ADD_TO_CART = 'add_to_cart',
  CATEGORY_PAGE = 'Viewed Category Page',
  BEGIN_CHECKOUT = 'begin_checkout',
  ERROR = 'errorOccured',
  DATA_LAYER_ERROR = 'dataLayer error',
  SELECT_ITEM = 'select_item',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  REMOVE_FROM_CART = 'remove_from_cart',
  PURCHASE = 'purchase',
  VIEW_CART = 'view_cart',
  ADD_SHIPPING_INFO = 'add_shipping_info',
}

export enum DATA_LAYER_LIST_NAME {
  CATALOG_BROWSE = 'Catalog Browse',
  SEARCH_RESULTS = 'Search Results',
  PRODUCT_CAROUSEL = 'Product Carousel',
  SALE_RESULTS = 'Sale Results',
}

export enum DATA_LAYER_LOCATION_ID {
  PRODUCT_LISTING_PAGE = 'Product Listing Page',
  HOMEPAGE_RECS_CAROUSEL = 'Homepage Recommendations Carousel',
  HOMEPAGE_ACCENTS_CAROUSEL = 'Homepage Accents Carousel',
  PDP_COLLECTION_CAROUSEL = 'PDP Collection Carousel',
  PDP_RECS_CAROUSEL = 'PDP Recommendations Carousel',
  PDP = 'Product Detail Page',
  CART = 'View Cart',
  CHECKOUT = 'Checkout',
}

// Cookie names
export const PURCHASE_EVENT_TRIGGER_COOKIE = 'purchase-event-trigger'

// Local storage interfaces

const LOCAL_STORAGE_DATA_KEY = 'DATA_LAYER_ITEMS'

interface IDataLayerLocalStorageItem {
  item_id: string
  list_name: string
  location_id: string
}

interface IDataLayerLocalStorage {
  items: IDataLayerLocalStorageItem[]
}

// Data Layer Interfaces

interface IEventData {
  action: string
  category: string
  label?: string
  nonInteraction?: boolean
  value?: number
}

interface IEcommerceItem {
  item_id: string
  item_name: string
  affiliation: string
  coupon?: string
  currency: string
  discount: number
  index: number
  item_brand: string
  item_category: string
  item_category2: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id: string
  item_list_name: string
  item_variant: string
  location_id: string
  price: number
  quantity?: number
}

interface IEcommercePurchaseCustomerAddressData {
  street: string
  city: string
  region: string
  postal_code: string
  country: string
}

interface IEcommercePurchaseCustomerData {
  email: string
  phone_number: string
  first_name: string
  last_name: string
  home_address: IEcommercePurchaseCustomerAddressData
}

interface IEcommerce {
  items?: IEcommerceItem[]
  currencyCode?: string
  value?: number
  checkoutStep?: number
  checkoutSection?: string
  coupon?: string | number
  transaction_id?: string
  affiliation?: string
  product_revenue?: number
  tax?: number
  shipping?: number
  discount?: number
  shipping_tier?: string
  payment_method?: string
  enhanced_conversion_data?: IEcommercePurchaseCustomerData
}

export interface IDataLayerObject {
  ecommerce?: IEcommerce | null
  errorOccured?: any
  errorType?: string
  event?: DATA_LAYER_EVENT_NAME
  eventData?: IEventData
  eventId?: string
}
interface IDataLayer {
  dataLayer: IDataLayerObject[]
}

interface ILoadItemDataParams {
  index: number
  listName: string
  locationId: string
}

// General methods

const PAGE_TYPE: { [index: string]: string } = {
  BLOG: 'blog',
  INSPIRATION: 'blog',
  CAREERS: 'careers',
  CART: 'cart',
  CATALOG: 'catalog',
  CHECKOUT: 'checkout',
  CHECKOUT_CONFIRMATION: 'checkout-confirmation',
  HOME: 'home',
  PRODUCT: 'product',
  SEARCH: 'search',
}

const CURRENCY_CODE = 'USD'

const getPage = (pagePathSplitUrl: string): string => {
  if (pagePathSplitUrl && PAGE_TYPE[pagePathSplitUrl.toUpperCase()]) {
    return PAGE_TYPE[pagePathSplitUrl.toUpperCase()]
  }
  return 'home'
}

const initializeGTM = (): string => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      const rawPagePath = window.location.pathname
      const pagePathSplitUrl = rawPagePath.split('/')[1]
      const page: string = getPage(pagePathSplitUrl)
      log(`Current page: ${page}`)
      return page
    } catch (error) {
      // TagManager will pick up on datalayer var 'errorOccured' and send event to google anayltics.
      handleError(error, win)
    }
  }
  return 'n/a'
}

const handleError = (error: any, win: IDataLayer) => {
  win.dataLayer.push({
    event: DATA_LAYER_EVENT_NAME.DATA_LAYER_ERROR,
    errorOccured: {
      error,
    },
  })
}

const debug: boolean = false
const log = (message: string) => {
  if (debug) {
    console.log(`dataLayer: ${message}`)
  }
}

const getWindow = (): IDataLayer | null => {
  if (process.browser) {
    if (window) {
      const win: IDataLayer = window as any
      if (win) {
        win.dataLayer = win.dataLayer || []
        return win
      } else {
        log('Warning: Window not present on window')
      }
    } else {
      log('Warning: No Window Present')
    }
  }
  return null
}

// --------------------------------------------------------------------- helpers --------------------------------------------------------------------------------------------

const clearDataLayerEcommerce = () => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    win.dataLayer.push({ ecommerce: null })
  }
}

const loadEcommerceItemFromProduct = async (
  product: IUnbxdProduct,
  params: ILoadItemDataParams,
): Promise<IEcommerceItem> => {
  const storeCode = getStoreCode()

  let currentProduct = product.variants?.length
    ? getCurrentProductFromVariants(product.variants as IUnbxdProduct[])
    : product

  // In some cases the variants with the full product info are missing in the original products list, so we get them from unbxd
  if (!currentProduct['COLOR']) {
    let sku = getSkuFromProduct(product)
    if (!sku && product.variants?.length) {
      const currentVariant = getCurrentProductFromVariants(product.variants as IUnbxdProduct[])
      sku = currentVariant.sku
    }
    const productFromUnbxd = await getProductsBySku(sku)
    currentProduct = productFromUnbxd.data.response.products[0]
  }

  const {
    title,
    catlevel1Name,
    catlevel2Name,
    catlevel3Name,
    catlevel4Name,
    brand,
    vendor,
    discount,
    categoryPath1,
    categoryPath2,
  } = currentProduct

  const finalPrice: number = currentProduct[`finalPrice_${storeCode}`]
  const price: number = currentProduct[`price_${storeCode}`]

  // workaround added because the unbxd information doesn't follow the current omnistudio interfaces definition
  let selectedBrand = typeof brand === 'object' ? brand[0] : brand
  if (!selectedBrand) {
    selectedBrand = typeof vendor === 'string' ? vendor : vendor.name
  }

  const actualDiscount = discount || (price - finalPrice).toFixed(2)

  const itemToAdd: IEcommerceItem = {
    item_id: getSkuFromProduct(product) || getSkuFromProduct(currentProduct),
    item_name: product.title || title,
    affiliation: 'Online Store',
    currency: CURRENCY_CODE,
    discount: Number(actualDiscount) || 0,
    index: params.index,
    item_brand: selectedBrand,
    item_category: catlevel1Name || categoryPath1[0],
    item_category2: catlevel2Name || categoryPath2[0],
    item_list_id: params.locationId,
    item_list_name: params.listName,
    item_variant: currentProduct['COLOR'] || 'N/A',
    location_id: params.locationId,
    price: finalPrice || 0,
  }

  if (catlevel3Name) {
    itemToAdd.item_category3 = catlevel3Name
  }
  if (catlevel4Name) {
    itemToAdd.item_category4 = catlevel4Name
  }

  return itemToAdd
}

const getEcommerceItemsFromCartItems = async (cartItems: ICartItem[]) => {
  const itemsToAdd: IEcommerceItem[] = []
  for (let i = 0; i < cartItems.length; i++) {
    const item = cartItems[i]
    const currentProduct = item.product as IUnbxdProduct

    const { listName, locationId } = getLocationAndListNameFromStorage(currentProduct.sku)
    const itemToAdd = await loadEcommerceItemFromProduct(currentProduct, { index: i, listName, locationId })
    itemToAdd.quantity = item.quantity

    itemsToAdd.push(itemToAdd)
  }
  return itemsToAdd
}

const getEcommerceItemsFromProductsList = async (products: IUnbxdProduct[], listName: string, locationId: string) => {
  const itemsToAdd: IEcommerceItem[] = []
  for (let i = 0; i < products.length; i++) {
    const currentProduct = products[i]

    const itemToAdd = await loadEcommerceItemFromProduct(currentProduct, { index: i, listName, locationId })

    itemsToAdd.push(itemToAdd)
  }
  return itemsToAdd
}

const getSkuFromProduct = (product: IUnbxdProduct) => {
  if (product.sku) {
    return product.sku
  } else if (product.id) {
    return product.id
  } else if (product.productUrl) {
    // Sometimes we don't have sku or id (Recommendations component)
    // So we use the product url which has this form (example): /product/543091899-t3521-10-cottage-lane-chairside-table-in-brown
    const url = product.productUrl.split('/')
    return url[2].split('-')[0]
  }
  return ''
}

const getShippingLabel = (delivery: IDeliveryInformation) => {
  if (delivery.needByDate) {
    return 'Later Date'
  }
  return delivery.pickupDeliveryType === 'P' ? 'Customer Pickup' : 'White Glove Delivery & Setup'
}

const getPaymentLabel = (orderData: IMerceOrder) => {
  const financedPayment = orderData.paymentDetails.methodsOfPayment.find(
    method => method.mopCode === IFinanceCardType.SYF,
  )
  if (financedPayment) {
    return 'Mor Furniture Credit Card'
  }
  return orderData.transactionResponse?.accountType + ' Credit Card'
}

const getLocationAndListNameFromStorage = (sku: string) => {
  const itemFromLocalStorage = getItemFromLocalStorage(sku)
  const listName = itemFromLocalStorage?.list_name || DATA_LAYER_LIST_NAME.CATALOG_BROWSE
  const locationId = itemFromLocalStorage?.location_id || DATA_LAYER_LOCATION_ID.PDP
  return {
    listName,
    locationId,
  }
}

const getLocationAndListNameForViewItem = (sku: string) => {
  if (!document.referrer || !document.referrer.includes(window.location.hostname)) {
    // Page manually loaded, use default values
    saveItemToLocalStorage(sku, DATA_LAYER_LIST_NAME.CATALOG_BROWSE, DATA_LAYER_LOCATION_ID.PDP)
    return {
      listName: DATA_LAYER_LIST_NAME.CATALOG_BROWSE,
      locationId: DATA_LAYER_LOCATION_ID.PDP,
    }
  } else {
    return getLocationAndListNameFromStorage(sku)
  }
}

const saveItemToLocalStorage = (sku: string, listName: string, locationId: string) => {
  let localStorageData = getStorageValue(LOCAL_STORAGE_DATA_KEY) as IDataLayerLocalStorage
  if (!localStorageData) {
    localStorageData = {
      items: [],
    }
  }

  const existingSku = localStorageData.items.findIndex(item => item.item_id === sku)
  if (existingSku !== -1) {
    localStorageData.items.splice(existingSku, 1)
  }

  localStorageData.items.push({
    item_id: sku,
    list_name: listName,
    location_id: locationId,
  })

  saveStorageValue(LOCAL_STORAGE_DATA_KEY, localStorageData)
}

const getItemFromLocalStorage = (sku: string) => {
  const localStorageData = getStorageValue(LOCAL_STORAGE_DATA_KEY) as IDataLayerLocalStorage
  if (!localStorageData) {
    return
  }
  return localStorageData.items.find(item => item.item_id === sku)
}

const removeItemFromLocalStorage = (sku: string) => {
  const localStorageData = getStorageValue(LOCAL_STORAGE_DATA_KEY) as IDataLayerLocalStorage
  if (!localStorageData) {
    return
  }

  const existingSku = localStorageData.items.findIndex(item => item.item_id === sku)
  if (existingSku !== -1) {
    localStorageData.items.splice(existingSku, 1)
  }

  saveStorageValue(LOCAL_STORAGE_DATA_KEY, localStorageData)
}

const removeItemsFromLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_DATA_KEY)
}

const currentTransactionIdExistsInLocalStorage = (transactionId: string) => {
  const transactionIDsFromStorage = getStorageValue('transId').toString()
  let transactionIDsFromStorageList: string[] = []
  if (transactionIDsFromStorage) {
    transactionIDsFromStorageList = transactionIDsFromStorage?.split(',')
  }

  if (!transactionIDsFromStorageList.includes(transactionId)) {
    if (transactionIDsFromStorageList.length === 3) {
      saveStorageValue('transId', transactionId)
    } else {
      transactionIDsFromStorageList.push(transactionId)
      saveStorageValue('transId', transactionIDsFromStorageList)
    }
    return false
  }
  return true
}

// --------------------------------------------------------------------- events --------------------------------------------------------------------------------------------

const onViewItem = async (product: IUnbxdProduct, eventId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      const { listName, locationId } = getLocationAndListNameForViewItem(product.sku)
      const itemToAdd = await loadEcommerceItemFromProduct(product, { index: 0, listName, locationId })
      win.dataLayer.push({
        eventId,
        event: DATA_LAYER_EVENT_NAME.VIEW_ITEM,
        ecommerce: {
          currencyCode: CURRENCY_CODE,
          items: [itemToAdd],
        },
      })
      log(`Item View: ${product.title}`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onViewItemsList = async (products: IUnbxdProduct[], listName: string, eventId: string, locationId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win && products?.length) {
    try {
      const items: IEcommerceItem[] = await getEcommerceItemsFromProductsList(products, listName, locationId)
      win.dataLayer.push({
        eventId,
        event: DATA_LAYER_EVENT_NAME.VIEW_ITEM_LIST,
        ecommerce: {
          items,
          currencyCode: CURRENCY_CODE,
        },
      })
      log(`View items list`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onBeginCheckout = async (cartItems: ICartItem[], cartPricing: IMorCartPrice, eventId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      if (cartItems) {
        const itemsToAdd = await getEcommerceItemsFromCartItems(cartItems)
        win.dataLayer.push({
          eventId,
          event: DATA_LAYER_EVENT_NAME.BEGIN_CHECKOUT,
          ecommerce: {
            currencyCode: CURRENCY_CODE,
            checkoutStep: 1,
            checkoutSection: 'Begin Checkout',
            value: cartPricing.orderTotal,
            items: itemsToAdd,
            coupon: cartPricing.promoSavingsLabel || '',
          },
        })
      }
      log(`Begin checkout`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onCheckoutStepTwo = async (cartItems: ICartItem[], cartPricing: IMorCartPrice | null) => {
  const win: IDataLayer | null = getWindow()

  if (win) {
    try {
      if (cartItems) {
        const itemsToAdd = await getEcommerceItemsFromCartItems(cartItems)
        win.dataLayer.push({
          event: DATA_LAYER_EVENT_NAME.ADD_SHIPPING_INFO,
          ecommerce: {
            currencyCode: CURRENCY_CODE,
            checkoutStep: 2,
            checkoutSection: 'Contact Information',
            value: cartPricing?.orderTotal,
            items: itemsToAdd,
            coupon: cartPricing?.promoSavingsLabel || '',
          },
        })
      }
      log(`Checkout step 2, contact information`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onViewCart = async (cartItems: ICartItem[], cartPricing: IMorCartPrice) => {
  const win: IDataLayer | null = getWindow()

  if (win) {
    try {
      if (cartItems) {
        const itemsToAdd = await getEcommerceItemsFromCartItems(cartItems)
        win.dataLayer.push({
          event: DATA_LAYER_EVENT_NAME.VIEW_CART,
          ecommerce: {
            currencyCode: CURRENCY_CODE,
            value: cartPricing.subTotal,
            items: itemsToAdd,
          },
        })
      }
      log(`View cart`)
    } catch (error) {
      win.dataLayer.push({
        event: DATA_LAYER_EVENT_NAME.ERROR,
        errorOccured: {
          error,
        },
      })
    }
  }
}

const onProductAddToCart = async (product: IUnbxdProduct, quantity: number, eventId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      const { listName, locationId } = getLocationAndListNameFromStorage(product.sku)
      const itemToAdd = await loadEcommerceItemFromProduct(product, { index: 0, listName, locationId })
      itemToAdd.quantity = quantity

      win.dataLayer.push({
        eventId,
        event: DATA_LAYER_EVENT_NAME.ADD_TO_CART,
        ecommerce: {
          currencyCode: CURRENCY_CODE,
          items: [itemToAdd],
        },
      })
      log(`product Added To Cart`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onProductCategoryList = (category: string, eventId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      win.dataLayer.push({
        eventId,
        event: DATA_LAYER_EVENT_NAME.CATEGORY_PAGE,
        eventData: {
          action: DATA_LAYER_EVENT_NAME.CATEGORY_PAGE,
          category: category,
        },
      })
      log(`Category page: ${category}`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onProductRemoveFromCart = async (removedCartItem: ICartItem, quantity?: number) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      const productToRemove = removedCartItem.product as IUnbxdProduct
      const { listName, locationId } = getLocationAndListNameFromStorage(productToRemove.sku)
      const itemToRemove = await loadEcommerceItemFromProduct(productToRemove, { index: 0, listName, locationId })
      itemToRemove.quantity = quantity || removedCartItem.quantity

      win.dataLayer.push({
        event: DATA_LAYER_EVENT_NAME.REMOVE_FROM_CART,
        ecommerce: {
          currencyCode: CURRENCY_CODE,
          items: [itemToRemove],
        },
      })

      removeItemFromLocalStorage(itemToRemove.item_id)

      log(`product Removed from Cart`)
    } catch (error) {
      win.dataLayer.push({
        event: DATA_LAYER_EVENT_NAME.ERROR,
        errorOccured: {
          error,
        },
      })
    }
  }
}

const onPurchase = async (order: IOrderResponse, eventId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win && order.data) {
    try {
      const orderData = order.data

      const { orderId, email, shippingAddress, paymentDetails, delivery, transactionResponse } = orderData

      const { order: items } = orderData.items

      const transactionId = transactionResponse?.transId

      if (!currentTransactionIdExistsInLocalStorage(transactionId)) {
        if (email) {
          setCookie('userE', email)
        }

        const customerData: IEcommercePurchaseCustomerData = {
          email: email || '',
          phone_number: delivery.pickupInformation?.phone || '',
          first_name: shippingAddress?.firstName,
          last_name: shippingAddress.lastName,
          home_address: {
            street: shippingAddress.street1,
            city: shippingAddress.city,
            region: shippingAddress.state,
            postal_code: shippingAddress.zip,
            country: 'US',
          },
        }

        const itemsToAdd = await getEcommerceItemsFromCartItems(items || [])

        win.dataLayer.push({
          eventId,
          event: DATA_LAYER_EVENT_NAME.PURCHASE,
          ecommerce: {
            currencyCode: CURRENCY_CODE,
            transaction_id: orderId,
            affiliation: 'Online Store',
            value: paymentDetails.orderTotal,
            product_revenue: paymentDetails.subTotal,
            tax: paymentDetails.taxCharge,
            shipping: paymentDetails.shipping,
            discount: paymentDetails.coupon || 0,
            shipping_tier: getShippingLabel(delivery),
            payment_method: getPaymentLabel(orderData),
            coupon: paymentDetails.promoSavings[0].label || '',
            enhanced_conversion_data: customerData,
            items: itemsToAdd,
          },
        })

        removeItemsFromLocalStorage()
      }
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onCheckoutError = (errorType: IErrorModalType) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      win.dataLayer.push({
        event: DATA_LAYER_EVENT_NAME.ERROR,
        errorType: errorType,
      })
      log(`checkout error - ` + errorType)
    } catch (error) {
      handleError(error, win)
    }
  }
}

const onProductClick = async (product: IUnbxdProduct, index: number, listName: string, locationId: string) => {
  const win: IDataLayer | null = getWindow()
  if (win) {
    try {
      const itemToAdd = await loadEcommerceItemFromProduct(product, { index, listName, locationId })

      //Save local storage data
      saveItemToLocalStorage(getSkuFromProduct(product), listName, locationId)

      win.dataLayer.push({
        event: DATA_LAYER_EVENT_NAME.SELECT_ITEM,
        ecommerce: {
          currencyCode: CURRENCY_CODE,
          items: [itemToAdd],
        },
      })
      log(`Select item`)
    } catch (error) {
      handleError(error, win)
    }
  }
}

export {
  initializeGTM,
  onViewItem,
  onViewItemsList,
  onProductClick,
  onProductAddToCart,
  onProductCategoryList,
  onPurchase,
  onProductRemoveFromCart,
  onViewCart,
  onBeginCheckout,
  onCheckoutError,
  clearDataLayerEcommerce,
  onCheckoutStepTwo,
}
