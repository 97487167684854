/* eslint-disable @typescript-eslint/camelcase */
import { envIs, ENVS } from '../envUtils'
import { IUnbxdProduct } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Common/Utils/unbxd/unbxdInterfaces'
import { ICartItem } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'
import calculateSubtotal from '../calculateSubtotal'
import { IProduct } from '../../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/PDP'
import { IMerceOrder } from '../../../pages/checkout'
import { getCookie } from '../cookie'

interface IKlaviyoProduct {
  ProductName: string
  SKU?: string
  ProductID: string
  Categories: string[]
  ProductCategories: string[]
  ImageURL?: string
  URL?: string
  Brand?: string
  Price: number | string
  SalePrice?: number | string
  OurPrice?: number | string
  CompareAtPrice?: number | string
  AddOns?: IKlaviyoProduct[]
  ItemPrice?: number | string
  Quantity?: number
  Name?: string
}
export interface IKlaviyo {
  push: (item: any[]) => void
  identify: () => {
    $email?: string
  }
}

export const getKlaviyo = (): IKlaviyo | null => {
  if (window) {
    const win: any = window as any
    const _learnq = win._learnq

    return _learnq || []
  } else {
    console.log('Warning: No Window Present')
  }

  return null
}

/**
 * If the value is not undefined, parse it as a number and format it to two decimal places
 * @param {number | string | undefined} val - The value to format.
 * @returns The `formatNumber` function returns a string.
 */
const formatNumber = (val: number | string | undefined) => {
  if (val !== undefined) {
    return Number.parseFloat(val.toString() ?? '').toFixed(2)
  }
  return ''
}

/**
 * It takes a product and returns a Klaviyo product object.
 * @param {IElementProduct} product - The product that you want to send to Klaviyo.
 * @param {string} [productUrl] - The URL of the product.
 */
const getKlaviyoProduct = (product: IUnbxdProduct, productUrl?: string): IKlaviyoProduct => ({
  ProductName: product.name,
  ProductID: product.id,
  Categories: product.categories?.map(category => category.title),
  ProductCategories: product.categories?.map(category => category.title),
  ImageURL: product.thumbnail,
  URL: productUrl,
  Brand: product.brand,
  Price: formatNumber(product.finalPrice_XX),
  CompareAtPrice: formatNumber(product.finalPrice_XX),
  SKU: product.sku,
})

const getKlaviyoProductMeta = (product: IProduct, productUrl?: string): IKlaviyoProduct => {
  const unbxdProduct = product as IUnbxdProduct
  return {
    ProductName: product.name,
    ProductID: product.id,
    Categories: [unbxdProduct.catlevel1Name, unbxdProduct.catlevel2Name],
    ProductCategories: [unbxdProduct.catlevel1Name, unbxdProduct.catlevel2Name],
    ImageURL: product.thumbnail,
    URL: productUrl,
    Brand: product.brand,
    Price: formatNumber(unbxdProduct?.finalPrice_XX),
    CompareAtPrice: formatNumber(unbxdProduct?.finalPrice_XX),
    SKU: product.sku,
  }
}

const getCartIdUrlParam = () => {
  const cartId: string | undefined = getCookie('anonId')
  return cartId ? `?aid=${cartId}` : ''
}

/**
 * When someone views a product, send a viewed product event to Klaviyo.
 * @param {IUnbxdProduct} product - IUnbxdProduct
 */
const onKlaviyoViewedProduct = async (product: IUnbxdProduct) => {
  if (product) {
    const productUrl = `${location.origin}${product.productUrl}`
    const Klaviyo: IKlaviyo | null = getKlaviyo()
    const item = getKlaviyoProduct(product, productUrl)

    if (envIs([ENVS.LOCAL])) {
      // just for local env
      console.log('Klaviyo -> Viewed Product', item)
    }

    if (Klaviyo) {
      Klaviyo.push(['track', 'Viewed Product', item])
    }
  }
}

/**
 * Send a request to the Klaviyo API to identify a user by their order
 * @param {any} order - The order object
 */
const onKlaviyoIdentifyByOrder = async (order: IMerceOrder) => {
  const address = order.billingAddress || order.shippingAddress
  const Klaviyo: IKlaviyo | null = getKlaviyo()

  const item = {
    $email: order.email,
    $first_name: address.firstName,
    $last_name: address.lastName,
    $phone_number: order.delivery.pickupInformation?.phone,
    $address1: address.street1,
    $address2: address.street2,
    $city: address.city,
    $region: address.state,
    $zip: address.zip,
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('Klaviyo -> identify', item)
  }

  if (Klaviyo) {
    Klaviyo.push(['identify', item])
  }
}

/**
 * When an order is successfully processed, takes an order object and sends it to Klaviyo
 * @param {IOrder} order - The order object that was placed.
 */
const onKlaviyoPlaceOrder = async (order: IMerceOrder) => {
  const Klaviyo: IKlaviyo | null = getKlaviyo()
  onKlaviyoIdentifyByOrder(order)

  const item = {
    $event_id: order.orderId,
    $value: formatNumber(order.paymentDetails.subTotal),
    OrderId: order.orderId,
    Tax: formatNumber(order.paymentDetails.taxCharge),
    SubTotal: formatNumber(order.paymentDetails.subTotal),
    Currency: 'USD',
    Shipping: formatNumber(order.paymentDetails.shipping),
    CheckoutURL: `${location.origin}/checkout-confirmation?token=${order?.orderId}&process=true`,
    Total: formatNumber(order.paymentDetails.orderTotal),
    CreditCardNumber: order.creditCardLastFour,
    CreditCardType: order?.transactionResponse?.accountType?.toUpperCase(),
    Categories: order.items.order
      .map((orderItem: any) => [orderItem.product.catlevel1Name, orderItem.product.catlevel2Name])
      .reduce((acc: any, categories: any) => [...acc, ...categories], []),
    ItemNames: order.items.order.map((orderItem: any) => orderItem.product.name),
    Brands: order.items.order.map((orderItem: any) => orderItem.product.brand) ?? [],
    Items: order.items.order.map((orderItem: any) => ({
      ...getKlaviyoProductMeta(
        orderItem.product,
        `${location.origin}${(orderItem.product as IUnbxdProduct).productUrl}`,
      ),
      Quantity: orderItem.quantity,
      ItemPrice: formatNumber(orderItem.product.finalPrice_XX),
      RowTotal: formatNumber(orderItem.product.finalPrice_XX * orderItem.quantity),
    })),
    BillingAddress: {
      FirstName: order.billingAddress.firstName,
      LastName: order.billingAddress.lastName,
      Address1: order.billingAddress.street1,
      Address2: order.billingAddress.street2,
      City: order.billingAddress.city,
      Zip: order.billingAddress.zip,
      Region: order.billingAddress.state,
      RegionCode: order.billingAddress.state,
      Phone: order.delivery.pickupInformation?.phone,
    },
    ShippingAddress: {
      FirstName: order.shippingAddress.firstName,
      LastName: order.shippingAddress.lastName,
      Address1: order.shippingAddress.street1,
      Address2: order.shippingAddress.street2,
      City: order.shippingAddress.city,
      Zip: order.shippingAddress.zip,
      Region: order.shippingAddress.state,
      RegionCode: order.shippingAddress.state,
      Phone: order.delivery.pickupInformation?.phone,
    },
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('Klaviyo -> Placed Order', item)
  }

  if (Klaviyo) {
    Klaviyo.push(['track', 'Placed Order', item])
  }
}

/**
 * When someone lands on the checkout page, sends a Klaviyo event to track the user starting a checkout.
 * @param {IMerceCartItem[]} cartItems - An array of cart items.
 * @param {string} email - the user contact email.
 */
const onKlaviyoStartedCheckout = async (cartItems: ICartItem[]) => {
  const Klaviyo: IKlaviyo | null = getKlaviyo()
  const item = {
    $value: calculateSubtotal(cartItems),
    ItemNames: cartItems.map((cartItem: ICartItem) => cartItem.product.name),
    CheckoutURL: `${location.origin}/checkout${getCartIdUrlParam()}`,
    Categories: cartItems
      .map((orderItem: any) => [orderItem.product.catlevel1Name, orderItem.product.catlevel2Name])
      .reduce((acc: any, categories: any) => [...acc, ...categories], []),
    Items: cartItems.map((cartItem: ICartItem) => {
      const productUrl = `${location.origin}${(cartItem.product as IUnbxdProduct).productUrl}`
      const product = getKlaviyoProductMeta(cartItem.product as IUnbxdProduct, productUrl)
      return { ...product, Quantity: cartItem.quantity /*, AddOns*/ }
    }),
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('Klaviyo -> Started Checkout', item)
  }

  if (Klaviyo) {
    Klaviyo.push(['track', 'Started Checkout', item])
  }
}

/**
 * When someone adds an item to their cart, send a Klaviyo event to the server.
 * @param {IUnbxdProduct} product - IProduct - the product that was added to the cart
 * @param {number} quantity - The quantity of the product that was added to the cart.
 * @param {IMerceCartItem[]} cartItems - An array of cart items.
 */
const onKlaviyoAddedToCart = async (product: IUnbxdProduct, quantity: number, cartItems: ICartItem[]) => {
  const Klaviyo: IKlaviyo | null = getKlaviyo()

  const item = {
    $value: calculateSubtotal(cartItems),
    AddedItemProductName: product.name,
    AddedItemProductID: product.id,
    AddedItemSKU: product.sku,
    AddedItemCategories: product.categories?.map(cat => cat.title),
    AddedItemImageURL: product.thumbnail,
    AddedItemURL: `${location.origin}${product.productUrl}`,
    AddedItemPrice: formatNumber(product.finalPrice_XX),
    AddedItemQuantity: quantity,
    ItemNames: cartItems.map((cartItem: ICartItem) => cartItem.product.name),
    CheckoutURL: `${location.origin}/checkout${getCartIdUrlParam()}`,
    Items: cartItems.map((cartItem: ICartItem) => {
      const productUrl = `${location.origin}${(cartItem.product as IUnbxdProduct).productUrl}`
      const product = getKlaviyoProductMeta(cartItem.product, productUrl)
      return {
        ...product,
        Quantity: cartItem.quantity,
      }
    }),
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('Klaviyo -> Added to Cart', item)
  }

  if (Klaviyo) {
    Klaviyo.push(['track', 'Added to Cart', item])
  }
}

const onKlaviyoIdentifyByEmail = async (email: string, firstName?: string, lastName?: string) => {
  const Klaviyo: IKlaviyo | null = getKlaviyo()

  if (Klaviyo) {
    if (firstName) {
      Klaviyo.push(['identify', { $email: email, $first_name: firstName, $last_name: lastName }])
    } else {
      Klaviyo.push(['identify', { $email: email }])
    }
  }

  if (envIs([ENVS.LOCAL])) {
    // just for local env
    console.log('identify', { $email: email })
  }
}

export const KLAVIYO_API_CALLS = {
  onKlaviyoPlaceOrder,
  onKlaviyoStartedCheckout,
  onKlaviyoAddedToCart,
  onKlaviyoViewedProduct,
  onKlaviyoIdentifyByEmail,
}
