import { ICartItem } from '../components/Omnimerse/cms/Frontend/omnistudio-frontend-components/src/Cart'

const getProductFinalPrice = (product: any, storeCode: string) => {
  return product[`finalPrice_${storeCode}`]
}

const calculateSubtotal = (cart: ICartItem[], storeCode?: string) => {
  let subTotal: number = 0
  for (const cartItem of cart) {
    subTotal += getProductFinalPrice(cartItem.product, storeCode || 'XX') * cartItem.quantity
  }
  return subTotal
}

export default calculateSubtotal
