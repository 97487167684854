interface ILocalStorageData {
  data: object
  expirationDate: number
}

// 24 hours
const DEFAULT_EXPIRATION_DATE = 86400000

const getStorageValue = (key: string) => {
  const savedValue = localStorage.getItem(key)
  let originalValue: string | object = ''
  try {
    if (savedValue) {
      originalValue = JSON.parse(savedValue)
    }
  } catch (err) {
    console.log(err)
  }
  return originalValue
}

const getStorageValueWithExpirationDate = (key: string) => {
  const savedValue = localStorage.getItem(key)
  if (savedValue) {
    const originalValue = JSON.parse(savedValue) as ILocalStorageData
    return originalValue
  }
  return null
}

const removeStorageValue = (key: string) => {
  localStorage.removeItem(key)
}

const saveStorageValue = (key: string, value: any) => {
  if (value) {
    const valueToStore = typeof value === 'string' ? value : JSON.stringify(value)
    localStorage.setItem(key, valueToStore)
  }
}

const saveStorageValueWithExpiration = (key: string, value: any) => {
  if (value) {
    const dataToStore: ILocalStorageData = {
      data: value,
      expirationDate: Date.now() + DEFAULT_EXPIRATION_DATE,
    }
    localStorage.setItem(key, JSON.stringify(dataToStore))
  }
}

export {
  getStorageValue,
  getStorageValueWithExpirationDate,
  removeStorageValue,
  saveStorageValue,
  saveStorageValueWithExpiration,
}
